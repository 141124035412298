import React from 'react';
import {Router, Switch, Route} from 'dva/router';
import dynamic from 'dva/dynamic'

function RouterConfig({history, app}) {

    const routes = [
        {
            path: '/',
            models: () => [import('./models/microservices')],
            component: () => import('./routes/index'),
        }, {
            path: '/rational',
            models: () => [import('./models/microservices')],
            component: () => import('./routes/index2'),
        }, {
            path: '/rational/apply',
            models: () => [import('./routes/rational/apply/model')],
            component: () => import('./routes/rational/apply/index'),
        }, {
            path: '/rational/mylist',
            models: () => [import('./routes/rational/mylist/model'), import('./models/common')],
            component: () => import('./routes/rational/mylist/index'),
        }, {
            path: '/rational/grouplist',
            models: () => [import('./routes/rational/grouplist/model'), import('./models/common')],
            component: () => import('./routes/rational/grouplist/index'),
        }, {
            path: '/rational/mylist/content',
            models: () => [import('./routes/rational/content/model'), import('./models/common')],
            component: () => import('./routes/rational/content/index'),
        }, {
            path: '/rational/scorelist',
            models: () => [import('./routes/rational/score/model'), import('./models/common')],
            component: () => import('./routes/rational/score/index'),
        }, {
            path: '/rational/score/content',
            models: () => [import('./routes/rational/scorecontent/model'), import('./models/common')],
            component: () => import('./routes/rational/scorecontent/index'),
        },
        {
            path: '/my/setting',
            models: () => [import('./models/ehr')],
            component: () => import('./routes/my/setting/index'),
        }, {
            path: '/signcard',
            models: () => [import('./routes/signcard/model')],
            component: () => import('./routes/signcard/index'),
        }, {
            path: '/takeleave',
            models: () => [import('./routes/takeleave/model')],
            component: () => import('./routes/takeleave/index'),
        }, {
            path: '/working',
            models: () => [import('./routes/working/model')],
            component: () => import('./routes/working/index'),
        }, {
            path: '/adjust',
            models: () => [import('./routes/adjust/model')],
            component: () => import('./routes/adjust/index'),
        }, {
            path: '/auunal',
            models: () => [import('./routes/auunal/model')],
            component: () => import('./routes/auunal/index'),
        }, {
            path: '/attendance',
            models: () => [import('./routes/attendance/model')],
            component: () => import('./routes/attendance/index'),
        }, {
            path: '/recruitment/list',
            models: () => [import('./routes/recruitment/list/model'), import('./models/common')],
            component: () => import('./routes/recruitment/list/index'),
        }, {
            path: '/recruitment/apply',
            models: () => [import('./routes/recruitment/apply/model')],
            component: () => import('./routes/recruitment/apply/index'),
        }, {
            path: '/recommend/list',
            models: () => [import('./routes/recommend/list/model'), import('./models/common')],
            component: () => import('./routes/recommend/list/index'),
        }, {
            path: '/recommend/apply',
            models: () => [import('./routes/recommend/apply/model')],
            component: () => import('./routes/recommend/apply/index'),
        }, {
            path: '/sdgrecommend/list/:ownerid',
            models: () => [import('./routes/sdgrecommend/list/model'), import('./models/common')],
            component: () => import('./routes/sdgrecommend/list/index'),
        }, {
            path: '/sdgrecommend/apply',
            models: () => [import('./routes/sdgrecommend/apply/model')],
            component: () => import('./routes/sdgrecommend/apply/index'),
        },

        {
            path: '/car',
            models: () => [import('./routes/car/model')],
            component: () => import('./routes/car/index'),
        }, {
            path: '/bonus',
            models: () => [import('./routes/bonus/model')],
            component: () => import('./routes/bonus/index'),
        }, {
            path: '/training',
            models: () => [import('./routes/training/model')],
            component: () => import('./routes/training/index'),
        }, {
            path: '/training2',
            models: () => [import('./routes/training2/model')],
            component: () => import('./routes/training2/index'),
        }, {
            path: '/childcareleave',
            models: () => [import('./routes/childcareleave/model')],
            component: () => import('./routes/childcareleave/index'),
        }, {
            path: '/sdgtraining',
            models: () => [import('./routes/sdgtraining/model')],
            component: () => import('./routes/sdgtraining/index'),
        }
        , {
            path: '/esignhome',
            models: () => [import('./models/microservices')],
            component: () => import('./routes/esignhome/index'),
        }, {
            path: '/esign/attendance',
            models: () => [import('./routes/esign/model')],
            component: () => import('./routes/esign/index'),
        }, {
            path: '/esign/bonuspunishment',
            models: () => [import('./routes/esign2/model')],
            component: () => import('./routes/esign2/index'),
        }, {
            path: '/esign/others',
            models: () => [import('./routes/esign3/model')],
            component: () => import('./routes/esign3/index'),
        }, {
            path: '/salarypassword',
            models: () => [import('./routes/salarypassword/model')],
            component: () => import('./routes/salarypassword/index'),
        }
    ]

    // const routes = [
    //   {
    //     path: '/',
    //     name: 'App',
    //     getComponent(nextState, cb) {
    //       console.log(nextState, cb)
    //       require.ensure([], (require) => {
    //         registerModel(app, require('./models/microservices'));
    //         cb(null, require('./routes/index'));
    //       });
    //     },
    //   },
    //   {
    //     path: '/my/setting',
    //     name: 'Setting',
    //     getComponent(nextState, cb) {
    //       require.ensure([], (require) => {
    //         registerModel(app, require('./models/ehr'));
    //         cb(null, require('./routes/my/setting/index'));
    //       });
    //     },
    //   },
    // ];
    // return <Router history={history} routes={routes} />;
    return (
        <Router history={history}>
            <Switch>
                {
                    routes.map(({path, ...dynamics}, key) => (
                        <Route key={key}
                               exact
                               path={path}
                               component={dynamic({
                                   app,
                                   ...dynamics,
                               })}
                        />
                    ))
                }
            </Switch>
        </Router>
    )
}

export default RouterConfig;
